@use '../../styles/_normalize.css';
@use '../../styles/fonts';
@use '../../styles/icons';
@use '../../styles/svg';
@use '../../styles/mixins';
@use '../../styles/visibility';
@use '../../styles/global-variables' as vars;
@use '../../styles/forms';
@use '../../styles/colors';
@use '../../styles/labels';
@use '../../styles/export';
@use '../../styles/ng2-toastr';
@import '@angular/cdk/overlay-prebuilt.css';

body {
	@include mixins.default-font;
	overflow-x: hidden;

	background-color: vars.$color-cl-background-1;
	overflow-anchor: none;

	.mask-overlay {
		cursor: pointer;
	}

	&.no-scroll {
		position: fixed;

		width: 100%;
		overflow-y: scroll;
	}

	&.disable-vertical-scrolling {
		overflow: hidden;
		// is nodig zodat Safari op iOS de pagina op de achtergrond niet laat scrollen
		-webkit-overflow-scrolling: touch;
	}
}

h2 {
	font-weight: normal;
	font-size: 1.25rem;
	line-height: 1.625rem;
}
h3 {
	font-weight: 700;
	font-size: 1rem;
	line-height: 1.375rem;
}
h4 {
	font-size: inherit;
	font-weight: 600;
}

a {
	color: vars.$color-cl-primary-1;
	font-weight: 600;
	text-decoration: none;

	outline: 0;
	cursor: pointer;
}

html,
body {
	height: 100%;
}

@-moz-document url-prefix() {
	// Firefox laat de omhoog/omlaag-pijltjes bij input type=number altijd zien, ook zonder focus
	// Hierdoor kun je de waarde veranderen zonder onBlur-event. Dat is onwenselijk.
	input[type='number']:not(:focus) {
		appearance: textfield;
		padding-right: 20px;
	}
}
